export const formattedCustomerData = (rawData: PitPortCustomersResponse): PitPortCustomersResponse => {
  return {
    hasMore: rawData.hasMore || false,
    pageIndex: rawData.pageIndex || 0,
    totalPages: rawData.totalPages || 0,
    data: rawData.data.map((item: PitPortCustomer) => ({
      createdAt: item.createdAt || "",
      createdBy: item.createdBy || "",
      customer: {
        address: item.customer?.address || "",
      },
      email: item.email || "",
      firstName: item.firstName || "",
      firstNameFurigana: item.firstNameFurigana || "",
      id: item.id || 0,
      lastName: item.lastName || "",
      lastNameFurigana: item.lastNameFurigana || "",
      numberPlate: {
        number: item.numberPlate?.number || "",
        region: item.numberPlate?.region || "",
        hiragana: item.numberPlate?.hiragana || "",
        regionCode: item.numberPlate?.regionCode || "",
      },
      phoneNumber: item.phoneNumber || "",
      status: item.status || 0,
    })),
  };
};
