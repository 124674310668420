import { useEffect, useState } from "react";
import styled from "styled-components";

import { Board, PageTop, Title, TitleArea } from "components/LayoutParts";
import Loading from "components/Loading";
import { useDebounce } from "hooks/useDebounce";
import usePageState from "hooks/usePageState";
import { AIMO_PARKING_USER_ROLE, AimoParkingUserRole } from "pages/AimoParking/constants/aimoParkingUserRole";
import { useFindAllUser } from "pages/AimoParking/User/api";
import DataTable from "pages/AimoParking/User/DataTable";
import ActionArea from "pages/AimoParking/User/DataTable/ActionArea";
import { jumpUpToElement } from "utils/animationUtils";

const OverWrapTitleArea = styled(TitleArea)`
  margin-bottom: -8px;
`;

const User = () => {
  const [totalPages, setTotalPages] = useState<number>(1);

  const { currentPage, setCurrentPage, sort, setSort, setFilter, filter, search, setSearch } = usePageState<
    SortByAimoParkingUser,
    AimoParkingUserRole
  >({
    pageSymbol: "aimoParkingUserListView",
    defaultSort: { sortByColumn: undefined, direction: undefined },
    defaultFilter: AIMO_PARKING_USER_ROLE.ALL,
  });
  const { debouncedValue: debouncedSearchKeyword } = useDebounce(search, 500);
  const { data, pageInfo } = useFindAllUser({
    keyword: debouncedSearchKeyword,
    pageIndex: currentPage,
    orderBy: sort.sortByColumn,
    order: sort.direction,
    role: filter === AIMO_PARKING_USER_ROLE.ALL ? undefined : filter,
  });

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearch(e.target.value);
  };

  const onFilterButtonClick = (role: AimoParkingUserRole) => {
    setCurrentPage(1);
    setFilter(role);
  };

  useEffect(() => {
    setTotalPages(pageInfo?.totalPages ?? 1);
    return () => {
      jumpUpToElement("root");
    };
  }, [pageInfo?.totalPages]);

  return (
    <Board>
      <PageTop />
      <OverWrapTitleArea>
        <Title>ユーザ</Title>
      </OverWrapTitleArea>
      <ActionArea
        searchKeyword={search}
        onSearchChange={onSearchChange}
        selectedFilter={filter}
        onFilterButtonClick={onFilterButtonClick}
      />
      {data ? (
        <DataTable
          userList={data}
          totalPages={totalPages}
          pageIndex={currentPage}
          sortOption={sort}
          setPageIndex={setCurrentPage}
          setSortOption={setSort}
          type="user"
        />
      ) : (
        <Loading />
      )}
    </Board>
  );
};

export default User;
