import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import { ContentArea as BaseContextArea, Board, TitleArea } from "components/LayoutParts";
import color from "constants/color";
import font from "constants/font";
import { useFindSystemNotificationById } from "pages/PitPort/SystemNotification/api";
import { dateFormat } from "utils/dateTimeUtils";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2px;
`;

const ContentArea = styled(BaseContextArea)`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
  padding: 32px;
  color: ${color.text.black};
`;

const HeaderArea = styled.div`
  display: flex;
  flex: 1;
  gap: 16px;
  justify-content: end;
  font-size: ${font.size12};
  gap: 16px;
  border-bottom: solid 1px ${color.form.border};
  padding-bottom: 14px;
`;

const HeaderItem = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
`;

const MessageArea = styled.div`
  white-space: pre-wrap;
`;

const Label = styled.div``;

const Value = styled.div``;

// パーツ定義
const SystemNotificationDetail = () => {
  const navigate = useNavigate();
  const { id: notificationId } = useParams();
  const { data: notification } = useFindSystemNotificationById(notificationId ?? "");

  return (
    <Board>
      <TitleArea>
        <Breadcrumb
          currentPageName={`${notification?.title ?? "読み込み中"}`}
          breadcrumbItems={[
            {
              pageName: `お知らせ`,
              onClick: () => navigate(`/pit_port/system_notification`),
            },
          ]}
        />
      </TitleArea>
      {notification && (
        <Wrap>
          <ContentArea>
            <HeaderArea>
              <HeaderItem>
                <Label>送信先</Label>
                <Value>Pit Port</Value>
              </HeaderItem>
              <HeaderItem>
                <Label>送信日</Label>
                <Value>{dateFormat(notification.createdAt, "YYYY/MM/DD HH:mm")}</Value>
              </HeaderItem>
            </HeaderArea>
            <MessageArea>{notification.content}</MessageArea>
          </ContentArea>
          <div />
        </Wrap>
      )}
    </Board>
  );
};

export default SystemNotificationDetail;
