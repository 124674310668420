import { ZodFormattedError } from "zod";

import { PropertyCsvSchema } from "pages/SyncPort/Company/Property/schema";
import { getFeatureTime } from "pages/SyncPort/Company/Property/utils/calculateAvailableTime";
import { templateHeader } from "pages/SyncPort/Company/Property/utils/constant";
import { SyncPortDailyRateType, getDailyRateType } from "pages/SyncPort/Company/Property/utils/convertDailyRateType";
import { SyncPortParkingType, getParkingType } from "pages/SyncPort/Company/Property/utils/convertParkingType";
import { SyncPortPaveMent, getPaveMent } from "pages/SyncPort/Company/Property/utils/convertPavement";
import { isTaxExempt } from "pages/SyncPort/Company/Property/utils/isTaxExempt";
import {
  SyncPortSupportCarModel,
  getSupportedCarModel,
} from "pages/SyncPort/Company/Property/utils/supportCarModelHelper";
import { SYNC_PORT_IMPORT_CSV_ERROR } from "pages/SyncPort/constants/csvErrors";
import { isEqualArray } from "utils/arrayUtils";
import { toISO } from "utils/dateTimeUtils";
import { saleCast, validateContractYears, replacePhoneNumber } from "utils/number.Utils";
import { safeString } from "utils/stringUtils";

type Replace<T, U> = {
  [P in keyof T]: P extends keyof U ? U[P] : T[P];
};

type SyncPortCreateSpace = {
  name: string; // 区画名
  usages: {
    useStartDate: string | null; // 利用開始日
    terminateDate: string | null; // 利用終了日
    contractor?: {
      name?: string; // 契約者名
      phoneNumber?: string; // 電話番号
    };
  }[];
};

type SyncPortCreateSpaceGroup = {
  monthlyRent: {
    default: {
      value: number; // 賃料
      isTaxExempt: boolean; // 課税 / 非課税
    };
    others: number; // その他月額賃料
  };
  initialCost: {
    deposit: {
      value: number; // 敷金
      isTaxExempt: boolean; // 課税 / 非課税
    };
    gratuityFee: {
      value: number; // 礼金
      isTaxExempt: boolean; // 課税 / 非課税
    };
    brokerageFee: number; // 仲介手数料
    others: number; // その他費用
  };
  supportCarModel: SyncPortSupportCarModel; // 対応車両
  feature: {
    time: {
      // 時間
      endDate?: string;
      startDate?: string;
    };
    type: SyncPortParkingType; // 区画タイプ
    pavement: SyncPortPaveMent; // 舗装
    hasRoof: boolean; // 屋根
    loan: string; // 貸与物
  };
  size: {
    height?: number; // 高さ
    length?: number; // 長さ
    carWidth?: number; // 車幅
    groundHeight?: number; // 地面高
    tireWidth?: number; // タイヤ幅
    weight?: number; // 重量
  };
  contract: {
    years: number; // 契約年数
    renewalFee: number; // 更新料
    dailyRateType: SyncPortDailyRateType; // 日額
  };
  remarks: string; // 備考
};

type SyncPortCreateSpaceGroupWithSpace = SyncPortCreateSpaceGroup & {
  spaces: SyncPortCreateSpace[];
};

export type SyncPortCreateProperty = {
  name: string; // 物件名
  address: string; // 住所
  itemNumber?: string; // 物件No
  personInCharge: string; // 担当者(UUID)
  remarks: string; // 備考
  spaceGroups: SyncPortCreateSpaceGroupWithSpace[];
};

type SyncPortCreatePropertyTmp = Replace<
  SyncPortCreateProperty,
  {
    spaceGroups: string[][];
  }
>;

// 物件情報に区画グループ情報を追加
const buildPropertyWithSpaceGroups = (property: string[], data: string[][]): SyncPortCreatePropertyTmp => {
  const propertyIdentifier = property.slice(0, 5);
  const spaceGroupsWithSpace = data
    .filter((row) => isEqualArray(propertyIdentifier, row.slice(0, 5)))
    .map((row) => row.slice(5));

  const address = property[1];

  return {
    name: property[0],
    address: address,
    itemNumber: property[2],
    remarks: property[3],
    personInCharge: property[4],
    spaceGroups: spaceGroupsWithSpace,
  };
};

// 区画グループ情報をオブジェクトに変換
const buildSpaceGroup = (spaceGroups: string[]): SyncPortCreateSpaceGroup => {
  return {
    monthlyRent: {
      default: {
        value: saleCast(spaceGroups[0], undefined) ?? 0,
        isTaxExempt: isTaxExempt(spaceGroups[1]),
      },
      others: saleCast(spaceGroups[2], undefined) ?? 0,
    },
    initialCost: {
      brokerageFee: saleCast(spaceGroups[3], undefined) ?? 0,
      deposit: {
        value: saleCast(spaceGroups[4], undefined) ?? 0,
        isTaxExempt: isTaxExempt(spaceGroups[5]),
      },
      gratuityFee: {
        value: saleCast(spaceGroups[6], undefined) ?? 0,
        isTaxExempt: isTaxExempt(spaceGroups[7]),
      },
      others: saleCast(spaceGroups[8], undefined) ?? 0,
    },
    supportCarModel: getSupportedCarModel(spaceGroups[9]),
    feature: {
      type: getParkingType(spaceGroups[10]),
      hasRoof: spaceGroups[11] === "あり",
      pavement: getPaveMent(spaceGroups[12]),
      time: getFeatureTime(spaceGroups[13]),
      loan: spaceGroups[14],
    },
    size: {
      height: saleCast(spaceGroups[15], true),
      length: saleCast(spaceGroups[16], true),
      carWidth: saleCast(spaceGroups[17], true),
      groundHeight: saleCast(spaceGroups[18], true),
      tireWidth: saleCast(spaceGroups[19], true),
      weight: saleCast(spaceGroups[20], true),
    },
    contract: {
      years: validateContractYears(saleCast(spaceGroups[21], true) ?? 1),
      renewalFee: saleCast(spaceGroups[22], undefined) ?? 0,
      dailyRateType: getDailyRateType(spaceGroups[23]),
    },
    remarks: spaceGroups[24],
  };
};

const buildSpaceUsageItem = (
  space: string[]
): {
  useStartDate: string | null;
  terminateDate: string | null;
} | null => {
  // 利用開始日が空文字列またはnullの場合、利用状況としてカウントしないためnullを返却
  if (!safeString(space[1], true)) {
    return null;
  }

  // 不正値の場合、空文字列を返却しBE側でバリデーションエラーを返却
  const useStartDate = toISO(safeString(space[1], true) ?? "");
  let terminateDate;
  if (safeString(space[2], true)) {
    // 不正値の場合、空文字列を返却しBE側でバリデーションエラーを返却
    terminateDate = toISO(safeString(space[2], true) ?? "") ?? "";
  } else {
    terminateDate = null;
  }

  return {
    useStartDate,
    terminateDate,
  };
};

// 区画情報を整形して返却
const buildSpaces = (spaces: string[][]): SyncPortCreateSpace[] => {
  const newSpaces: SyncPortCreateSpace[] = [];

  const addOrUpdateSpace = (space: string[]) => {
    if (space[0] === "") return;

    const spaceUsage = buildSpaceUsageItem(space);
    const existingSpaceIndex = newSpaces.findIndex((item) => item.name === space[0]);

    const constrictorName = safeString(space[3], true);
    const constrictorPhoneNumber = replacePhoneNumber(safeString(space[4], true) ?? "");
    const contractor =
      constrictorName || constrictorPhoneNumber
        ? { name: constrictorName, phoneNumber: constrictorPhoneNumber }
        : undefined;

    // 既存の区画がある場合は追加、ない場合は新規追加
    if (existingSpaceIndex !== -1) {
      if (spaceUsage) {
        newSpaces[existingSpaceIndex].usages.push({
          useStartDate: spaceUsage.useStartDate,
          terminateDate: spaceUsage.terminateDate,
          contractor,
        });
      }
    } else {
      // 区画が存在しない場合は新規追加
      newSpaces.push({
        name: space[0],
        usages: spaceUsage ? [{ ...spaceUsage, contractor }] : [],
      });
    }
  };

  spaces.forEach(addOrUpdateSpace);

  return newSpaces;
};

// 重複する情報を取り除く
const getUniqueItems = (items: string[][], sliceLength: number): string[][] => {
  const itemArray = items.map((item) => JSON.stringify(item.slice(0, sliceLength)));
  const uniqueSet = new Set(itemArray);
  return Array.from(uniqueSet).map((item) => JSON.parse(item));
};

// 物件一覧からユニークな物件情報を取得し、区画グループ情報も分別
const buildPropertiesWithSpaceGroups = (data: string[][]): SyncPortCreatePropertyTmp[] => {
  const uniqueProperties = getUniqueItems(data, 5);
  return uniqueProperties.map((property: string[]) => buildPropertyWithSpaceGroups(property, data));
};

// 区画グループ情報に区画情報をセット
const setSpaceGroupWithSpaces = (
  uniqueSpaceGroup: string[],
  spaceGroups: string[][]
): SyncPortCreateSpaceGroupWithSpace => {
  const copyUniqueSpaceGroups = [...uniqueSpaceGroup];
  const spaces = spaceGroups
    .filter((group) => isEqualArray(uniqueSpaceGroup, group.slice(0, -5)))
    .filter((space) => !space.every((item) => !item))
    .map((group) => group.slice(-5));
  const uniqueSpaces = getUniqueItems(spaces, 5);
  const buildedSpaces = buildSpaces(uniqueSpaces);

  return {
    ...buildSpaceGroup(copyUniqueSpaceGroups),
    spaces: buildedSpaces,
  };
};

// 区画グループ一覧からユニークな区画グループを取得し、区画情報も分別
const buildNewSpaceGroups = (spaceGroups: string[][]): SyncPortCreateSpaceGroupWithSpace[] => {
  const uniqueSpaceGroups = getUniqueItems(spaceGroups, -5);
  const spaceGroupsWithSpaces = uniqueSpaceGroups
    .filter((spaceGroup) => !spaceGroup.every((item) => !item))
    .map((uniqueSpaceGroup) => setSpaceGroupWithSpaces(uniqueSpaceGroup, spaceGroups));
  return spaceGroupsWithSpaces;
};

const countPropertyInfoList = (properties: SyncPortCreateProperty[]) => {
  let countProperty = 0;
  let countSpaceGroup = 0;
  let countSpace = 0;
  let countFreeSpace = 0;
  let countFreeScheduleSpace = 0;
  let countFullSpace = 0;

  for (let i = 0; i < properties.length; i++) {
    countProperty++;
    for (let j = 0; j < properties[i].spaceGroups.length; j++) {
      countSpaceGroup++;
      countSpace += properties[i].spaceGroups[j].spaces.length;
      for (let k = 0; k < properties[i].spaceGroups[j].spaces.length; k++) {
        for (let l = 0; l < properties[i].spaceGroups[j].spaces[k].usages.length; l++) {
          if (
            properties[i].spaceGroups[j].spaces[k].usages[l].useStartDate == null &&
            properties[i].spaceGroups[j].spaces[k].usages[l].terminateDate == null
          ) {
            countFreeSpace++;
          } else if (
            properties[i].spaceGroups[j].spaces[k].usages[l].useStartDate != null &&
            properties[i].spaceGroups[j].spaces[k].usages[l].terminateDate != null
          ) {
            countFreeScheduleSpace++;
          } else {
            countFullSpace++;
          }
        }
      }
    }
  }

  return { countProperty, countSpaceGroup, countSpace, countFreeSpace, countFreeScheduleSpace, countFullSpace };
};

// CSVデータ(rows)から物件情報をグルーピングし、物件情報を生成
// 1列目から5列目までが物件情報
// 6列目から30列目までが区画グループ情報
// 31列目から35列目までが区画情報・利用状況
// 基本処理：それぞれをユニークに処理しその後に結合しオブジェクトに変換
export const generateUniquePropertyInfoList = (
  rows: string[][]
): Promise<{
  properties: SyncPortCreateProperty[];
  countProperty: number;
  countSpaceGroup: number;
  countSpace: number;
  countFreeSpace: number;
  countFreeScheduleSpace: number;
  countFullSpace: number;
  errors: {
    row: number;
    message: ZodFormattedError<typeof PropertyCsvSchema>;
  }[];
}> => {
  return new Promise((resolve, reject) => {
    const [header, ...originalData] = rows;

    // トリム処理と空白行の削除
    const data = originalData
      .map((row) => row.map((item) => item.trim()))
      .filter((row) => row.some((item) => item !== ""));

    if (!isEqualArray(header, templateHeader)) {
      reject(new Error(SYNC_PORT_IMPORT_CSV_ERROR.INVALID_HEADER.errorCode));
    }
    if (data.length === 0) {
      reject(new Error(SYNC_PORT_IMPORT_CSV_ERROR.NO_DATA.errorCode));
    }

    // バリデーションを行う
    const errors: {
      row: number;
      message: ZodFormattedError<typeof PropertyCsvSchema>;
    }[] = [];

    data
      .map((row, index) => {
        const rowObject = Object.fromEntries(header.map((key, i) => [key, row[i]]));

        const parsedResult = PropertyCsvSchema.safeParse(rowObject);

        // 0-indexedのため+1
        // さらに1行目はヘッダーのため+1
        const currentActualRowIndex = index + 2;

        if (!parsedResult.success) {
          const formattedErrors = parsedResult.error.format();

          Object.entries(formattedErrors).forEach(([, value]) => {
            if (!Array.isArray(value) && Array.isArray(value._errors) && value._errors.length > 0) {
              value._errors.forEach((errorMessage) => {
                console.error(`行 ${currentActualRowIndex} でエラー: ${errorMessage}`);
              });
            }
          });

          errors.push({
            row: currentActualRowIndex,
            message: formattedErrors,
          });
        }

        return parsedResult.data;
      })
      .filter(Boolean);

    const propertiesWithSpaceGroups = buildPropertiesWithSpaceGroups(data);
    const newProperties = propertiesWithSpaceGroups.map((property: SyncPortCreatePropertyTmp) => ({
      ...property,
      spaceGroups: buildNewSpaceGroups(property.spaceGroups),
    }));

    const { countProperty, countSpaceGroup, countSpace, countFreeSpace, countFreeScheduleSpace, countFullSpace } =
      countPropertyInfoList(newProperties);

    resolve({
      properties: newProperties,
      countProperty,
      countSpaceGroup,
      countSpace,
      countFreeSpace,
      countFreeScheduleSpace,
      countFullSpace,
      errors,
    });
  });
};
