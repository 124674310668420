import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { TitleArea, FooterArea, ContentArea as BaseContentArea, FormRow, Board } from "components/LayoutParts";
import TextareaForm from "components/TextareaForm";
import TextForm from "components/TextForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import font from "constants/font";
import { SYSTEM_MAINTAIN_PLACEHOLDER } from "pages/PitPort/constants/systemMaintainPlaceHolder";
import {
  CONFIRM_MODAL_CONFIG,
  FAILED_MODAL_CONFIG,
  INITIAL_MODAL_CONFIG,
  SUCCEED_MODAL_CONFIG,
} from "pages/PitPort/modalTemplate";
import { useCreateSystemNotification } from "pages/PitPort/SystemNotification/api";

// パーツ定義
const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const Wrap = styled.div`
  margin-top: 10px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  flex: 1;
`;
const ContentWrap = styled.div`
  gap: 16px;
`;
const ContentArea = styled(BaseContentArea)`
  display: flex;
`;
const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Label = styled.div<{ top?: boolean }>`
  min-width: 200px;
  margin: 0 5px;
  padding-right: 18px;
  align-self: ${({ top }) => (top ? "flex-start" : "center")};
  margin-top: ${({ top }) => (top ? "14px" : "0")};
`;
const CurrentPageWrap = styled.p`
  font-size: ${font.size24};
  font-weight: bold;
  line-height: 40px;
`;

const CreateSystemNotification = () => {
  // フォームの値
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [modalConfig, setModalConfig] = useState<UserModalConfig>(INITIAL_MODAL_CONFIG);
  const [isVisible, setIsVisible] = useState(false);

  const changeTitle = (e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value);
  const changeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value);

  const navigate = useNavigate();
  const { create } = useCreateSystemNotification();

  const isErrorTitle = title === "";
  const isErrorMessage = message === "";
  const canSubmit = !isErrorTitle && !isErrorMessage;

  const onSubmit = useCallback(async () => {
    const response = await create({
      content: message,
      title,
    }).catch((e) => {
      setModalConfig(FAILED_MODAL_CONFIG({ onClose: () => setIsVisible(false) }));
      setIsVisible(true);
      throw e;
    });

    if (response.status === 201) {
      setModalConfig(
        SUCCEED_MODAL_CONFIG({
          message: "送信しました",
          onClose: () => navigate(`/pit_port/system_notification`),
        })
      );
      setIsVisible(true);
    }
  }, [create, message, navigate, title, setModalConfig, setIsVisible]);

  const handleOpenSubmitModal = useCallback(() => {
    setModalConfig(
      CONFIRM_MODAL_CONFIG({
        negative: () => setIsVisible(false),
        positive: onSubmit,
      })
    );
    setIsVisible(true);
  }, [onSubmit, setModalConfig, setIsVisible]);

  return (
    <Board>
      <TitleArea>
        <CurrentPageWrap>お知らせを送信</CurrentPageWrap>
      </TitleArea>
      <Wrap>
        <ContentWrap>
          <ContentArea>
            <FormRow>
              <Label>タイトル</Label>
              <TextForm
                type="text"
                width="350px"
                value={title}
                placeholder="例）システムメンテナンスのお知らせ"
                required
                onChange={changeTitle}
                errorMessage="タイトルを入力してください"
                isError={isErrorTitle}
              />
            </FormRow>
            <FormRow>
              <Label top>本文</Label>
              <TextareaForm
                width="832px"
                minHeight={400}
                errorMessage="本文を入力してください"
                onChange={changeMessage}
                value={message}
                isError={isErrorMessage}
                placeholder={SYSTEM_MAINTAIN_PLACEHOLDER}
              />
            </FormRow>
          </ContentArea>
        </ContentWrap>
        <FooterArea>
          <CancelWrap>
            <Button
              type="secondary"
              onClick={() => navigate("/pit_port/system_notification")}
              label="キャンセル"
              width="160px"
            />
          </CancelWrap>
          <SaveWrap>
            <Button
              type="primary"
              onClick={handleOpenSubmitModal}
              label="お知らせを送信"
              disabled={!canSubmit}
              width="160px"
            />
          </SaveWrap>
        </FooterArea>
      </Wrap>

      <UserModal isVisible={isVisible} config={modalConfig} />
    </Board>
  );
};

export default CreateSystemNotification;
