import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Papaparse from "papaparse";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ZodFormattedError } from "zod";

import Button from "components/Button";
import DeletedAlert from "components/DeletedAlert";
import { ContentArea as BaseContextArea, FooterArea, TitleArea } from "components/LayoutParts";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import SyncPortPropertyUploadModal, {
  SyncPortPropertyUploadModalConfig,
} from "pages/SyncPort/Company/component/SyncPortPropertyUploadModal";
import { useBulkInsertProperty } from "pages/SyncPort/Company/features/api";
import { PropertyCsvSchema } from "pages/SyncPort/Company/Property/schema";
import {
  SyncPortCreateProperty,
  generateUniquePropertyInfoList,
} from "pages/SyncPort/Company/Property/utils/parseProperties";
import {
  SYNC_PORT_ERROR_KEYS,
  SYNC_PORT_IMPORT_CSV_ERROR,
  SYNC_PORT_IMPORT_CSV_ERROR_CODE,
} from "pages/SyncPort/constants/csvErrors";
import { handleReadFileExcel } from "utils/dataFileUtil";

const UploadContainer = styled.div`
  min-width: 160px;
  height: 120px;
  background-color: ${color.white};
  display: flex;
  align-items: center;
  border: 1px solid ${color.border};
`;

const UploadWrap = styled.div`
  display: flex;
  margin: 0 48px;
  align-items: center;
  gap: 28px;
  color: ${color.text.gray};
  position: relative;
`;

const Label = styled.div`
  font-size: ${font.size16};
  width: 110px;
`;

const Message = styled.div<{ isBold?: boolean }>`
  font-weight: ${({ isBold }) => (isBold ? "bold" : "normal")};
  color: ${({ isBold }) => (isBold ? color.text.black : color.text.gray)};
`;

const FormWrap = styled.div`
  flex: 1;
`;
const ContentArea = styled(BaseContextArea)`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  border: none;
  padding-bottom: 30px;
  height: fit-content;
`;

const ContentWrap = styled.div`
  flex: 1;
`;

const ContentLabel = styled.div`
  font-size: ${font.size20};
  font-weight: 600;
  padding: 18px 0 24px 0;
`;
const FormRowWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const Value = styled.div`
  font-size: ${font.size16};
  line-height: 24px;
`;

export const FormRow = styled.div<{ minHeight?: string; hideBorder?: boolean }>`
  width: 100%;
  padding: 16px 0;
  margin: 0 16px;
  border-bottom: solid 1px ${color.border};
  border-bottom: ${({ hideBorder }) => (hideBorder ? "none" : `auto`)};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: ${(props) => props.minHeight};
  gap: 24px;
  font-size: ${font.size16};
`;

const PropertyEmptyInfo = styled.div`
  background-color: ${color.white};
  margin: 16px 0 0 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${color.text.gray};
  min-height: 400px;
`;
const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
  font-weight: 600;
  gap: 4px;
  display: flex;
  align-items: center;
`;

const MarginTop = styled.div`
  margin-top: 28px;
`;

const UpdateTitle = styled.div`
  font-size: ${font.size24};
  font-weight: 600;
`;

const ImportProperty = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { bulkInsertProperty } = useBulkInsertProperty();
  const [selected, setSelected] = useState(false);
  const [fileName, setFileName] = useState<string>("");
  const [properties, setProperties] = useState<SyncPortCreateProperty[]>([]);
  const [countPropertyInfo, setCountPropertyInfo] = useState({
    countProperty: 0,
    countSpaceGroup: 0,
    countSpace: 0,
    countFreeSpace: 0,
    countFreeScheduleSpace: 0,
    countFullSpace: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isPropertyModalShow, setIsPropertyModalShow] = useState(false);
  const [propertyModalConfig, setPropertyModalConfig] = useState<SyncPortPropertyUploadModalConfig>({
    type: "loading",
  });
  const [isVisible, setIsVisible] = useState(false);
  const [modalConfig, setModalConfig] = useState<UserModalConfig>({
    onClickOutside: () => {
      return;
    },
    subtitle: "完了しました",
    main: {
      buttonType: "secondary",
      onClick: () => {
        return;
      },
      label: "閉じる",
    },
  });
  const [propertyValidationErrors, setPropertyValidationErrors] = useState<
    {
      row: number;
      message: ZodFormattedError<typeof PropertyCsvSchema>;
    }[]
  >([]);

  const isError = propertyValidationErrors.length > 0;
  const canSubmit = selected && !isError;

  const onOpenErrorModal = (errorText?: string) => {
    setIsVisible(true);
    setIsLoading(false);
    setModalConfig({
      subtitle: errorText ?? "フォーマットが正しくありません",
      main: {
        label: "閉じる",
        buttonType: "secondary",
        onClick: () => {
          setIsVisible(false);
        },
      },
      onClickOutside: () => {
        setIsVisible(false);
      },
    });
  };

  const handleInvalidFileTypeErrorModal = () => {
    setIsVisible(true);
    setIsLoading(false);
    setModalConfig({
      subtitle: "ファイル形式が正しくありません",
      main: {
        label: "閉じる",
        buttonType: "secondary",
        onClick: () => {
          setIsVisible(false);
        },
      },
      errorMessage: "CSVファイルを選択してください",
      onClickOutside: () => {
        setIsVisible(false);
      },
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    if ((acceptedFiles[0] as File)?.type !== "text/csv") return handleInvalidFileTypeErrorModal();
    const reader = new FileReader();
    reader.onload = async (e: ProgressEvent<FileReader>) => {
      setIsLoading(true);
      const data = handleReadFileExcel(e);
      if (typeof data === "string") {
        const parser = Papaparse.parse(data);
        const rows = parser.data as string[][];
        const { properties, errors, ...countPropertyInfo } = await generateUniquePropertyInfoList(rows).catch(
          (e: { message: SYNC_PORT_IMPORT_CSV_ERROR_CODE }) => {
            onOpenErrorModal(
              SYNC_PORT_IMPORT_CSV_ERROR[
                (Object.keys(SYNC_PORT_IMPORT_CSV_ERROR) as SYNC_PORT_ERROR_KEYS[]).find(
                  (key: SYNC_PORT_ERROR_KEYS) => SYNC_PORT_IMPORT_CSV_ERROR[key].errorCode === e.message
                ) as SYNC_PORT_ERROR_KEYS
              ]?.message
            );
            throw new Error(e.message);
          }
        );
        setPropertyValidationErrors(errors);
        setCountPropertyInfo(countPropertyInfo);
        setProperties(properties);
      } else {
        setSelected(true);
        setFileName(acceptedFiles[0].name);
      }
      setSelected(true);
      setFileName(acceptedFiles[0].name);
      setIsLoading(false);
    };
    setIsLoading(false);
    reader.readAsText(acceptedFiles[0]);
  }, []);

  const handleCloseModal = ({ callNavigate, useTimer }: { callNavigate?: boolean; useTimer?: boolean }) => {
    setTimeout(
      () => {
        setIsPropertyModalShow(false);
        callNavigate && navigate(`/sync_port/company/${id}/property`);
      },
      useTimer ? 800 : 0
    );
  };

  const handleSubmit = async () => {
    setPropertyModalConfig({
      type: "loading",
    });
    setIsPropertyModalShow(true);
    const { status } = await bulkInsertProperty({
      companyId: id ?? "",
      properties: properties,
    });

    if (status !== 201) {
      setPropertyModalConfig({
        type: "failed",
        onClickOutSide: () => handleCloseModal({}),
        onClose: () => handleCloseModal({}),
      });
      return;
    }
    setPropertyModalConfig({
      type: "complete",
      onClickOutSide: () => handleCloseModal({ callNavigate: true }),
    });
    return handleCloseModal({ callNavigate: true, useTimer: true });
  };

  const mapPropertyError = (error: ZodFormattedError<typeof PropertyCsvSchema>): string[] => {
    if (!error) return [];

    return Object.entries(error)
      .map(([key, value]) => {
        // グローバルエラーの場合
        if (key === "_errors") {
          // value が配列の場合はそのまま、オブジェクトの場合は _errors プロパティを使用
          const messages = Array.isArray(value) ? value : value._errors || [];
          if (messages.length === 0) {
            return null;
          }
          return `エラー: ${messages.join(", ")}`;
        } else {
          // 各フィールドのエラーの場合
          // value は { _errors: string[] } というオブジェクトになっているはず
          const messages = (value as { _errors?: string[] })._errors || [];
          if (messages.length === 0) {
            return null;
          }
          return `${key}: ${messages.join(", ")}`;
        }
      })
      .filter((msg): msg is string => msg !== null);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
    multiple: false,
    onDropRejected: handleInvalidFileTypeErrorModal,
    // 500MBが上限
    maxSize: 524288000,
    accept: {
      "text/csv": [],
    },
  });

  return (
    <>
      <TitleArea>
        <UpdateTitle>CSVで物件登録</UpdateTitle>
      </TitleArea>
      {isError && (
        <DeletedAlert
          message={propertyValidationErrors.map((error, i) => {
            return (
              <div key={i}>
                {mapPropertyError(error.message).map((err) => (
                  <div key={i}>{`行${error.row}  ${err}`}</div>
                ))}
              </div>
            );
          })}
        />
      )}
      <UploadContainer {...getRootProps()}>
        <UploadWrap>
          <Label>CSVファイル</Label>
          <input {...getInputProps()} />
          <Button
            label="ファイルを選択"
            type="secondary"
            width="160px"
            height="40px"
            onClick={open}
            isLoading={isLoading}
            disabled={isLoading}
          />
          <Message isBold={selected}>{selected ? fileName : "選択されていません"}</Message>
        </UploadWrap>
      </UploadContainer>
      {isError ? (
        <PropertyEmptyInfo>CSVファイルのバリデーションエラーが発生しています</PropertyEmptyInfo>
      ) : !selected ? (
        <PropertyEmptyInfo>
          CSVファイルを選択すると
          <br />
          アップロードして登録する物件情報を確認できます
        </PropertyEmptyInfo>
      ) : (
        <ContentWrap>
          <ContentArea>
            <ContentLabel>アップロードして登録する物件情報</ContentLabel>
            <FormWrap>
              <FormRowWrap>
                <FormRow>
                  <Label>物件数</Label>
                  <Value>{countPropertyInfo.countProperty}</Value>
                </FormRow>
                <FormRow>
                  <Label>空車数</Label>
                  <Value>{countPropertyInfo.countFreeSpace}</Value>
                </FormRow>
              </FormRowWrap>
              <FormRowWrap>
                <FormRow>
                  <Label>区画グループ数</Label>
                  <Value>{countPropertyInfo.countSpaceGroup}</Value>
                </FormRow>
                <FormRow>
                  <Label>空き予定数</Label>
                  <Value>{countPropertyInfo.countFreeScheduleSpace}</Value>
                </FormRow>
              </FormRowWrap>
              <FormRowWrap>
                <FormRow hideBorder>
                  <Label>区画数</Label>
                  <Value>{countPropertyInfo.countSpace}</Value>
                </FormRow>
                <FormRow hideBorder>
                  <Label>満車</Label>
                  <Value>{countPropertyInfo.countFullSpace}</Value>
                </FormRow>
              </FormRowWrap>
            </FormWrap>
          </ContentArea>
        </ContentWrap>
      )}
      <MarginTop />
      <FooterArea>
        <CancelWrap>
          <Button
            type="secondary"
            onClick={() => navigate(`/sync_port/company/${id}/property`)}
            label="キャンセル"
            width="160px"
          />
        </CancelWrap>
        <SaveWrap>
          <Annotation>
            <FontAwesomeIcon icon={faExclamationCircle} size="lg" color={color.warning} />
            登録した物件情報は削除することができません
          </Annotation>
          <Button
            type="primary"
            onClick={handleSubmit}
            label="アップロードして登録"
            disabled={!canSubmit}
            width="180px"
          />
        </SaveWrap>
      </FooterArea>
      <SyncPortPropertyUploadModal isVisible={isPropertyModalShow} config={propertyModalConfig} />
      <UserModal isVisible={isVisible} config={modalConfig} />
    </>
  );
};

export default ImportProperty;
