import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { Board, PageTop, Title, TitleArea } from "components/LayoutParts";
import Loading from "components/Loading";
import { sortDirection } from "constants/direction";
import usePageState from "hooks/usePageState";
import { useFindAllSystemNotification } from "pages/AimoParking/SystemNotification/api";
import DataTable from "pages/AimoParking/SystemNotification/DataTable";
import { jumpUpToElement } from "utils/animationUtils";

const ButtonWrap = styled.div`
  margin-left: auto;
`;

const Margin = styled.div`
  margin-top: 16px;
`;

const SystemNotification = () => {
  const [totalPages, setTotalPages] = useState<number>(1);
  const [sortOption, setSortOption] = useState<SortOption<SortByAimoParkingSystemNotification>>({
    sortByColumn: "title",
    direction: sortDirection.desc,
  });

  const { currentPage, setCurrentPage } = usePageState({
    pageSymbol: "aimoParkingSystemNotificationListView",
  });
  const navigate = useNavigate();
  const { data, pageInfo } = useFindAllSystemNotification({
    pageIndex: currentPage,
  });

  const onClickCompanyAdd = () => {
    navigate("/aimo_parking/system_notification/create");
  };

  useEffect(() => {
    setTotalPages(pageInfo?.totalPages ?? 1);
    return () => {
      jumpUpToElement("root");
    };
  }, [pageInfo?.totalPages]);

  return (
    <Board>
      <PageTop />
      <TitleArea>
        <Title>お知らせ</Title>
        <ButtonWrap>
          <Button label="お知らせを作成" onClick={onClickCompanyAdd} type="primary" />
        </ButtonWrap>
      </TitleArea>
      <Margin />
      {data ? (
        <DataTable
          notificationList={data}
          totalPages={totalPages}
          pageIndex={currentPage}
          sortOption={sortOption}
          setPageIndex={setCurrentPage}
          setSortOption={setSortOption}
        />
      ) : (
        <Loading />
      )}
    </Board>
  );
};

export default SystemNotification;
