import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

import { AlertArea } from "components/LayoutParts";
import color from "constants/color";

const UpdatedAlertArea = styled(AlertArea)`
  padding: unset;
`;

const Message = styled.span`
  color: ${color.text.black};
`;

const MessageWrap = styled.div`
  display: flex;
  gap: 4px;
`;

const MessageArrayWrap = styled.div`
  display: flex;
  gap: 4px;
`;

const Wrap = styled.div`
  margin: 20px 40px;
`;

const DeletedAlert = ({ message }: { message: string | string[] | JSX.Element[] }) => {
  return (
    <UpdatedAlertArea>
      <Wrap>
        {Array.isArray(message) ? (
          <MessageArrayWrap>
            <FontAwesomeIcon
              icon={faCircleExclamation}
              style={{
                paddingTop: "3px",
              }}
            />
            <div>
              {message.map((msg, i) => (
                <MessageWrap key={i}>
                  <Message>{msg}</Message>
                </MessageWrap>
              ))}
            </div>
          </MessageArrayWrap>
        ) : typeof message === "string" ? (
          <MessageWrap>
            <FontAwesomeIcon
              icon={faCircleExclamation}
              style={{
                paddingTop: "3px",
              }}
            />
            <Message>{message}</Message>
          </MessageWrap>
        ) : (
          <></>
        )}
      </Wrap>
    </UpdatedAlertArea>
  );
};

export default DeletedAlert;
