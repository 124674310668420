export const AIMO_PARKING_EDGE_DEVICE_STATUS = {
  // 未承認
  UNCERTIFIED: 0,
  // カメラ未設定
  CAMERA_NOT_SET: 1,
  // 車室未設定
  PARKING_SPACE_NOT_SET: 2,
  // 設定済
  SETTING_COMPLETE: 3,
  // 正常
  NORMAL: 4,
  // 異常
  ABNORMAL: 5,
  // 応答なし
  NO_RESPONSE: 6,
  // 撤去
  REMOVED: 7,
} as const;

export const AIMO_PARKING_EDGE_DEVICE_STATUS_JA = {
  UNCERTIFIED: "未承認",
  CAMERA_NOT_SET: "カメラ未設定",
  PARKING_SPACE_NOT_SET: "車室未設定",
  SETTING_COMPLETE: "設定済",
  NORMAL: "正常",
  ABNORMAL: "異常",
  NO_RESPONSE: "応答なし",
  REMOVED: "撤去",
} as const;

export type AimoParkingEdgeDeviceStatus =
  typeof AIMO_PARKING_EDGE_DEVICE_STATUS[keyof typeof AIMO_PARKING_EDGE_DEVICE_STATUS];

export const generateAimoParkingEdgeDeviceStatusJa = (status: AimoParkingEdgeDeviceStatus) => {
  switch (status) {
    case AIMO_PARKING_EDGE_DEVICE_STATUS.UNCERTIFIED:
      return AIMO_PARKING_EDGE_DEVICE_STATUS_JA.UNCERTIFIED;
    case AIMO_PARKING_EDGE_DEVICE_STATUS.CAMERA_NOT_SET:
      return AIMO_PARKING_EDGE_DEVICE_STATUS_JA.CAMERA_NOT_SET;
    case AIMO_PARKING_EDGE_DEVICE_STATUS.PARKING_SPACE_NOT_SET:
      return AIMO_PARKING_EDGE_DEVICE_STATUS_JA.PARKING_SPACE_NOT_SET;
    case AIMO_PARKING_EDGE_DEVICE_STATUS.SETTING_COMPLETE:
      return AIMO_PARKING_EDGE_DEVICE_STATUS_JA.SETTING_COMPLETE;
    case AIMO_PARKING_EDGE_DEVICE_STATUS.NORMAL:
      return AIMO_PARKING_EDGE_DEVICE_STATUS_JA.NORMAL;
    case AIMO_PARKING_EDGE_DEVICE_STATUS.ABNORMAL:
      return AIMO_PARKING_EDGE_DEVICE_STATUS_JA.ABNORMAL;
    case AIMO_PARKING_EDGE_DEVICE_STATUS.NO_RESPONSE:
      return AIMO_PARKING_EDGE_DEVICE_STATUS_JA.NO_RESPONSE;
    case AIMO_PARKING_EDGE_DEVICE_STATUS.REMOVED:
      return AIMO_PARKING_EDGE_DEVICE_STATUS_JA.REMOVED;
  }
};
