import styled from "styled-components";

import color from "constants/color";
import { PARKING_AVAILABILITY } from "constants/parkingAvailability";
import { SpaceUsageAvailability } from "pages/SyncPort/Company/Property/Space/features/api";

const colors = {
  EMPTY: color.tag.empty,
  FULL: color.tag.full,
  AVAILABLE_SCHEDULE: color.tag.availableSchedule,
};

const ColorText = styled.div<{ status: SpaceUsageAvailability }>`
  color: ${({ status }) => {
    return colors[status];
  }};
  font-weight: 600;
  font-size: 14px;
`;
const ParkingAvailabilityTag = ({ status }: { status: SpaceUsageAvailability }) => {
  return <ColorText status={status}>{PARKING_AVAILABILITY[status]?.text}</ColorText>;
};

export default ParkingAvailabilityTag;
