import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { Board, ContentArea, PageTop, Title, TitleArea } from "components/LayoutParts";

const ButtonWrap = styled.div`
  margin-left: auto;
`;

/**
 * 貸主一覧画面(仮)
 */
const OwnerEntity = () => {
  const navigate = useNavigate();
  const onClickOwnerEntityAdd = () => {
    navigate("/pit_port/owner_entities/create");
  };

  return (
    <Board>
      <PageTop />
      <TitleArea>
        <Title>貸主</Title>
        <ButtonWrap>
          <Button label="貸主を追加" onClick={onClickOwnerEntityAdd} type="primary" />
        </ButtonWrap>
      </TitleArea>
      <ContentArea>
        <div>
          <Title>貸主一覧画面</Title>
          <br />
          {/* TODO: 貸主一覧を表示する */}
          <Link to="/pit_port/owner_entities/1/info">ランディット</Link>
        </div>
      </ContentArea>
    </Board>
  );
};

export default OwnerEntity;
