import { useNavigate } from "react-router-dom";

import EmptyTable from "components/EmptyTable";
import { Table, Heading, FieldName, Row, FieldValueWithBorder } from "components/LayoutParts";
import Pagination from "components/Pagination";
import SortButton from "components/SortButton";
import { dateFormat } from "utils/dateTimeUtils";

const companyTableColumn: { label: string; flex: number; sortKey?: string }[] = [
  {
    label: "タイトル",
    flex: 47,
  },
  {
    label: "送信日",
    flex: 11,
  },
];

const DataTable = ({
  notificationList,
  totalPages,
  pageIndex,
  sortOption,
  setPageIndex,
  setSortOption,
}: {
  notificationList: PitPortSystemNotification[];
  totalPages: number;
  pageIndex: number;
  sortOption: SortOption<SortByPitPortSystemNotification>;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  setSortOption: React.Dispatch<React.SetStateAction<SortOption<SortByPitPortSystemNotification>>>;
}) => {
  const navigate = useNavigate();

  const onClickRow = (notificationId: string) => navigate(`/pit_port/system_notification/${notificationId}`);

  const onClickAddNotification = () => navigate("/pit_port/system_notification/create");

  const handleSetSort = (sortOption: SortOption<SortByPitPortSystemNotification>) => {
    setPageIndex(1);
    setSortOption(sortOption);
  };

  return (
    <>
      <Table>
        <Heading>
          {companyTableColumn.map((column, i) => (
            <FieldName
              key={column.label}
              flex={column.flex}
              isSort={sortOption.sortByColumn === column.sortKey}
              lastColumn={i === companyTableColumn.length - 1}
            >
              {column.label}
              {column.sortKey && (
                <SortButton
                  currentSortOption={sortOption}
                  sortByKey={column.sortKey as SortByPitPortSystemNotification}
                  setSortOption={handleSetSort}
                />
              )}
            </FieldName>
          ))}
        </Heading>
        {notificationList.length > 0 ? (
          notificationList.map((notification) => (
            <Row key={notification.id} onClick={() => onClickRow(notification.id)}>
              <FieldValueWithBorder flex={companyTableColumn[0].flex}>{notification.title}</FieldValueWithBorder>
              <FieldValueWithBorder flex={companyTableColumn[1].flex}>
                {dateFormat(notification.createdAt, "YYYY/MM/DD")}
              </FieldValueWithBorder>
            </Row>
          ))
        ) : (
          <EmptyTable handleClick={onClickAddNotification} label="お知らせを追加" message="お知らせが見つかりません" />
        )}
      </Table>
      <Pagination totalPages={totalPages} pageIndex={pageIndex} setPageIndex={setPageIndex} />
    </>
  );
};

export default DataTable;
