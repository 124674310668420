import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { Board, PageTop, Title, TitleArea } from "components/LayoutParts";
import Loading from "components/Loading";
import { useDebounce } from "hooks/useDebounce";
import usePageState from "hooks/usePageState";
import { useFindAllCompany } from "pages/AimoParking/Company/api";
import DataTable from "pages/AimoParking/Company/DataTable";
import ActionArea from "pages/AimoParking/Company/DataTable/ActionArea";
import { AimoParkingCompanyStatus } from "pages/AimoParking/constants/companyStatus";
import { jumpUpToElement } from "utils/animationUtils";

const OverWrapTitleArea = styled(TitleArea)`
  margin-bottom: -8px;
`;

const ButtonWrap = styled.div`
  margin-left: auto;
`;

const Company = () => {
  const [totalPages, setTotalPages] = useState<number>(1);

  const { currentPage, setCurrentPage, sort, setSort, setFilter, filter, search, setSearch } = usePageState<
    SortByAimoParkingCompany,
    AimoParkingCompanyStatus
  >({
    pageSymbol: "aimoParkingCompanyListView",
    defaultSort: { sortByColumn: undefined, direction: undefined },
  });

  const navigate = useNavigate();
  const { debouncedValue: debouncedSearchKeyword } = useDebounce(search, 500);
  const { data, pageInfo } = useFindAllCompany({
    keyword: debouncedSearchKeyword,
    pageIndex: currentPage,
    orderBy: sort.sortByColumn,
    order: sort.direction,
  });

  console.log(sort);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearch(e.target.value);
  };

  const onFilterButtonClick = (status: AimoParkingCompanyStatus) => {
    setCurrentPage(1);
    setFilter(status);
  };

  const onClickCompanyAdd = () => {
    navigate("/aimo_parking/company/create");
  };

  useEffect(() => {
    setTotalPages(pageInfo?.totalPages ?? 1);
    return () => {
      jumpUpToElement("root");
    };
  }, [pageInfo?.totalPages]);

  return (
    <Board>
      <PageTop />
      <OverWrapTitleArea>
        <Title>管理会社</Title>
        <ButtonWrap>
          <Button label="管理会社を追加" onClick={onClickCompanyAdd} type="primary" />
        </ButtonWrap>
      </OverWrapTitleArea>
      <ActionArea
        searchKeyword={search}
        onSearchChange={onSearchChange}
        selectedFilter={filter}
        onFilterButtonClick={onFilterButtonClick}
      />
      {data ? (
        <DataTable
          companyList={data}
          totalPages={totalPages}
          pageIndex={currentPage}
          sortOption={sort}
          setPageIndex={setCurrentPage}
          setSortOption={setSort}
        />
      ) : (
        <Loading />
      )}
    </Board>
  );
};

export default Company;
