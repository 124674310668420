import { faBuilding, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { TitleArea, Title, Board } from "components/LayoutParts";
import TabBar from "components/TabBar";

const TabBarWrap = styled.div`
  padding: 0 0 37px 0;
`;

/**
 * 貸主詳細画面のタブ
 */
const TitleTabArea = () => {
  const { id: ownerEntityId } = useParams();
  const navigate = useNavigate();

  return (
    <Board>
      <TitleArea>
        <Title>貸主</Title>
      </TitleArea>
      <TabBarWrap>
        <TabBar
          options={[
            {
              label: "貸主情報",
              onClick: () => navigate(`/pit_port/owner_entities/${ownerEntityId}/info`),
              Icon: <FontAwesomeIcon icon={faBuilding} width={12} height={16} />,
              width: 80,
              matchUrlKey: ["owner_entities/", "info/"],
            },
            {
              label: "貸主ユーザ",
              onClick: () => navigate(`/pit_port/owner_entities/${ownerEntityId}/owner_users`),
              Icon: <FontAwesomeIcon icon={faUserFriends} width={18} height={14} />,
              width: 96,
              matchUrlKey: ["owner_entities/", "owner_users/"],
            },
          ]}
        />
      </TabBarWrap>
      <Outlet />
    </Board>
  );
};

export default TitleTabArea;
