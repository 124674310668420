import { useState } from "react";

import PageStateContext from "contexts/PageStateContext";

const PageStateProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState({});

  const value = { state, setState };

  return <PageStateContext.Provider value={value}>{children}</PageStateContext.Provider>;
};

export default PageStateProvider;
