/**
 * 貸主ユーザーのロール
 * owner: 貸主
 * general: 一般ユーザー
 */
export const PIT_PORT_OWNER_USER_ROLE = {
  OWNER: "owner",
  GENERAL: "general",
} as const;

export type PitPortOwnerUserRole = typeof PIT_PORT_OWNER_USER_ROLE[keyof typeof PIT_PORT_OWNER_USER_ROLE];

/**
 * 貸主ユーザーのロールのラベル
 */
export const PIT_PORT_OWNER_USER_ROLE_LABEL = {
  [PIT_PORT_OWNER_USER_ROLE.OWNER]: "貸主or代表",
  [PIT_PORT_OWNER_USER_ROLE.GENERAL]: "一般ユーザー",
} as const;
