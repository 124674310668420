import { isEmail } from "class-validator";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import { TitleArea, FooterArea, ContentArea as BaseContentArea, FormRow, Board } from "components/LayoutParts";
import TextForm from "components/TextForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import { AIMO_PARKING_REGEX_RULES } from "pages/AimoParking/constants/validation";
import { FAILED_MODAL_CONFIG, INITIAL_MODAL_CONFIG, SUCCEED_MODAL_CONFIG } from "pages/PitPort/Customer/modalTemplate";
import { useCreateOwnerUser } from "pages/PitPort/OwnerEntity/OwnerUser/api";

// パーツ定義
const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const Wrap = styled.div`
  margin-top: 10px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
`;
const ContentWrap = styled.div`
  gap: 16px;
`;
const ContentArea = styled(BaseContentArea)`
  display: flex;
`;
const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
`;
const Label = styled.div<{ top?: boolean }>`
  min-width: 200px;
  margin: 0 5px;
  padding-right: 18px;
  align-self: ${({ top }) => (top ? "flex-start" : "center")};
  margin-top: ${({ top }) => (top ? "14px" : "0")};
`;
const SubLabelLeft = styled.div`
  width: 32px;
  margin-right: 24px;
`;
const SubLabelRight = styled.div`
  width: 32px;
  margin: 0 24px;
`;

/**
 * 貸主ユーザー作成画面
 */
const CreateOwnerUser = () => {
  // フォームの値
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastNameKana, setLastNameKana] = useState("");
  const [firstNameKana, setFirstNameKana] = useState("");
  const [email, setEmail] = useState("");
  // TODO: ロールを選択できるようにする
  // const [role, setRole] = useState<OwnerUserRole | undefined>();
  const [modalConfig, setModalConfig] = useState<UserModalConfig>(INITIAL_MODAL_CONFIG);
  const [isVisible, setIsVisible] = useState(false);

  const navigate = useNavigate();
  const { create } = useCreateOwnerUser();
  const { id: ownerEntityId } = useParams();

  const changeLastName = (e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value);
  const changeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value);
  const changeLastNameKana = (e: React.ChangeEvent<HTMLInputElement>) => setLastNameKana(e.target.value);
  const changeFirstNameKana = (e: React.ChangeEvent<HTMLInputElement>) => setFirstNameKana(e.target.value);
  const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  // バリデーション
  const isErrorLastName = lastName.length === 0 || !AIMO_PARKING_REGEX_RULES.FULL_WIDTH_CHARACTER.REGEX.test(lastName);
  const isErrorFirstName =
    firstName.length === 0 || !AIMO_PARKING_REGEX_RULES.FULL_WIDTH_CHARACTER.REGEX.test(firstName);
  const isErrorLastNameKana = lastNameKana.length === 0 || !AIMO_PARKING_REGEX_RULES.KATAKANA.REGEX.test(lastNameKana);
  const isErrorFirstNameKana =
    firstNameKana.length === 0 || !AIMO_PARKING_REGEX_RULES.KATAKANA.REGEX.test(firstNameKana);
  const isErrorEmail = !(email.length > 0) || !isEmail(email);
  const canSubmit =
    !isErrorLastName && !isErrorFirstName && !isErrorLastNameKana && !isErrorFirstNameKana && !isErrorEmail;

  const onSubmit = useCallback(async () => {
    if (!ownerEntityId) return;

    try {
      const response = await create(ownerEntityId, {
        email,
        firstName,
        firstNameFurigana: firstNameKana,
        lastName,
        lastNameFurigana: lastNameKana,
      });

      if (response.status === 201) {
        setModalConfig(
          SUCCEED_MODAL_CONFIG({
            onClose: () => navigate(`/pit_port/owner_entities/${ownerEntityId}/owner_users`),
            message: "追加しました",
          })
        );
        setIsVisible(true);
      } else {
        setModalConfig(FAILED_MODAL_CONFIG(() => setIsVisible(false)));
        setIsVisible(true);
      }
    } catch (e) {
      console.error(e);

      setModalConfig(FAILED_MODAL_CONFIG(() => setIsVisible(false)));
      setIsVisible(true);
    }
  }, [create, navigate, ownerEntityId, email, firstName, firstNameKana, lastName, lastNameKana]);

  return (
    <Board>
      <TitleArea>
        <Breadcrumb
          currentPageName="貸主ユーザを追加"
          breadcrumbItems={[
            {
              // TODO: 貸主を取得して表示する
              // pageName: `${ownerEntity?.name ?? "読み込み中"}`,
              pageName: "貸主ユーザー",
              onClick: () => navigate(`/pit_port/owner_entities/${ownerEntityId}/owner_users`),
            },
          ]}
        />
      </TitleArea>
      <Wrap>
        <ContentWrap>
          <ContentArea>
            <FormRow>
              <Label>名前</Label>
              <SubLabelLeft>姓</SubLabelLeft>
              <TextForm
                type="text"
                key="lastName"
                width="200px"
                value={lastName}
                placeholder="例）工藤"
                required
                onChange={changeLastName}
                isError={isErrorLastName}
                errorMessage="姓を入力してください"
              />
              <SubLabelRight>名</SubLabelRight>
              <TextForm
                type="text"
                key="firstName"
                width="200px"
                value={firstName}
                placeholder="例）敬三"
                required
                onChange={changeFirstName}
                isError={isErrorFirstName}
                errorMessage="名を入力してください"
              />
            </FormRow>
            <FormRow>
              <Label>名前 （カナ）</Label>
              <SubLabelLeft>セイ</SubLabelLeft>
              <TextForm
                key="lastNameKana"
                type="text"
                width="200px"
                value={lastNameKana}
                placeholder="例）クドウ"
                required
                onChange={changeLastNameKana}
                isError={isErrorLastNameKana}
                errorMessage="セイを入力してください"
              />
              <SubLabelRight>メイ</SubLabelRight>
              <TextForm
                key="firstNameKana"
                type="text"
                width="200px"
                value={firstNameKana}
                placeholder="例）ケイゾウ"
                required
                onChange={changeFirstNameKana}
                isError={isErrorFirstNameKana}
                errorMessage="メイを入力してください"
              />
            </FormRow>
            <FormRow>
              <Label>メールアドレス</Label>
              <TextForm
                type="email"
                width="350px"
                value={email}
                placeholder="例）info@landit.co.jp"
                required
                onChange={changeEmail}
                errorMessage="メールアドレスを入力してください"
                isError={isErrorEmail}
              />
            </FormRow>
          </ContentArea>
        </ContentWrap>
        <FooterArea>
          <CancelWrap>
            <Button
              type="secondary"
              onClick={() => navigate(`/pit_port/owner_entities/${ownerEntityId}/owner_users`)}
              label="キャンセル"
              width="160px"
            />
          </CancelWrap>
          <SaveWrap>
            <Annotation>追加するまで変更内容は反映されません</Annotation>
            <Button type="primary" onClick={onSubmit} label="このユーザを追加" disabled={!canSubmit} width="160px" />
          </SaveWrap>
        </FooterArea>
      </Wrap>

      <UserModal isVisible={isVisible} config={modalConfig} />
    </Board>
  );
};

export default CreateOwnerUser;
