import { useCallback } from "react";

import { usePitPortClient } from "hooks/pitPort/usePitPortClient";
import { useSwr } from "hooks/pitPort/useSwr";
import { PIT_PORT_PAGINATION_DEFAULT_SIZE } from "pages/PitPort/constants/pagination";
import { formatQueryParams } from "utils/stringUtils";

export const useFindAllSystemNotification = ({
  keyword,
  order,
  orderBy,
  pageIndex,
  pageSize = PIT_PORT_PAGINATION_DEFAULT_SIZE,
}: {
  pageIndex?: number;
  orderBy?: string;
  order?: string;
  keyword?: string;
  pageSize?: number;
}) => {
  const { data, error } = useSwr<PitPortSystemNotificationsResponse>(
    `/system-notification?${formatQueryParams({
      keyword,
      pageSize,
      pageIndex,
      orderBy,
      order,
    })}`
  );

  return {
    data: data?.data,
    error,
    pageInfo: data?.pagination && {
      hasMore: data.pagination.hasMore,
      pageIndex: data.pagination.pageIndex,
      totalPages: data.pagination.totalPages,
      totalItems: data.pagination.totalItems,
    },
  };
};

export const useFindSystemNotificationById = (id: string) => {
  const { data, error } = useSwr<PitPortSystemNotification>(`/system-notification/${id}`);

  return { data, error };
};

export const useCreateSystemNotification = () => {
  const client = usePitPortClient();

  const create = useCallback(
    async (data: PitPortSystemNotificationParameter) => {
      return await client.execute({
        method: "POST",
        url: "/system-notification",
        data,
      });
    },
    [client]
  );

  return { create };
};
