import React from "react";
import styled from "styled-components";

import { ContentArea as BaseContextArea } from "components/LayoutParts";
import PublicTag from "components/PublicTag";
import color from "constants/color";
import font from "constants/font";
import { calculateAvailableTime } from "pages/SyncPort/Company/Property/utils/calculateAvailableTime";
import { convertDailyRateType } from "pages/SyncPort/Company/Property/utils/convertDailyRateType";
import { convertParkingType } from "pages/SyncPort/Company/Property/utils/convertParkingType";
import { convertPavementType } from "pages/SyncPort/Company/Property/utils/convertPavement";
import { findLargestSupportCarModelFromObjectFromStringifiedJson } from "pages/SyncPort/Company/Property/utils/supportCarModelHelper";
import { convertNumberToLocaleString } from "utils/stringUtils";

const ContentArea = styled(BaseContextArea)`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 32px;
  padding: 32px;
`;
export const FormRow = styled.div<{ minHeight?: string; hideBorder?: boolean }>`
  width: 100%;
  margin: 0;
  border-top: solid 1px ${color.border};
  border-top: ${({ hideBorder }) => (hideBorder ? "none" : `auto`)};
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: center;
  justify-content: space-between;
  min-height: 44px;
`;

const HalfSizeContent = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  display: flex;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.p`
  font-size: ${font.size16};
  font-weight: 600;
  padding-bottom: 10px;
`;

const LeftItem = styled.p`
  max-width: 50%;
`;

const RightItem = styled.p<{ maxWidth?: string }>`
  max-width: ${({ maxWidth }) => maxWidth ?? "50%"};
`;

const SpaceGroupInfo = ({ spaceGroupDetail }: { spaceGroupDetail: Omit<SyncPortSpaceGroupDetail, "images"> }) => {
  console.log(spaceGroupDetail, "####");
  return (
    <ContentArea>
      <HalfSizeContent>
        <Wrap>
          <Label>月額費用</Label>
          <FormRow>
            <LeftItem>賃料</LeftItem>
            <RightItem>{convertNumberToLocaleString(spaceGroupDetail.monthlyRent.default.value)}</RightItem>
          </FormRow>
          <FormRow>
            <LeftItem>その他月額費用（保証料）</LeftItem>
            <RightItem>{convertNumberToLocaleString(spaceGroupDetail.monthlyRent.others)}</RightItem>
          </FormRow>
        </Wrap>
        <Wrap>
          <Label>初期費用</Label>
          <FormRow>
            <LeftItem>仲介手数料</LeftItem>
            <RightItem>{convertNumberToLocaleString(spaceGroupDetail.initialCost.brokerageFee)}</RightItem>
          </FormRow>
          <FormRow>
            <LeftItem>敷金・保証金（返却あり）</LeftItem>
            <RightItem>{convertNumberToLocaleString(spaceGroupDetail.initialCost.deposit.value)}</RightItem>
          </FormRow>
          <FormRow>
            <LeftItem>礼金</LeftItem>
            <RightItem>{convertNumberToLocaleString(spaceGroupDetail.initialCost.gratuityFee.value)}</RightItem>
          </FormRow>
          <FormRow>
            <LeftItem>その他費用</LeftItem>
            <RightItem>{convertNumberToLocaleString(spaceGroupDetail.initialCost.others)}</RightItem>
          </FormRow>
        </Wrap>
        <Wrap>
          <Label>対応車両</Label>
          <FormRow>
            <LeftItem>対応</LeftItem>
            <RightItem maxWidth="70%">
              {findLargestSupportCarModelFromObjectFromStringifiedJson(
                // api側の値がstringifyされたjsonなので注意
                spaceGroupDetail.supportCarModel as unknown as string
              )}
            </RightItem>
          </FormRow>
        </Wrap>
        <Wrap>
          <Label>公開設定</Label>
          <FormRow>
            <LeftItem>利用状況</LeftItem>
            <RightItem>
              <PublicTag isPublic={spaceGroupDetail.isAvailabilityPublic} />
            </RightItem>
          </FormRow>
          <FormRow>
            <LeftItem>公開設定</LeftItem>
            <RightItem>
              <PublicTag isPublic={spaceGroupDetail.isPublic} />
            </RightItem>
          </FormRow>
        </Wrap>
      </HalfSizeContent>
      <HalfSizeContent>
        <Wrap>
          <Label>特徴</Label>
          <FormRow>
            <LeftItem>識別</LeftItem>
            <RightItem>{convertParkingType(spaceGroupDetail.feature.type)}</RightItem>
          </FormRow>
          <FormRow>
            <LeftItem>屋根</LeftItem>
            <RightItem>{spaceGroupDetail.feature.hasRoof ? "あり" : "なし"}</RightItem>
          </FormRow>
          <FormRow>
            <LeftItem>舗装</LeftItem>
            <RightItem>{convertPavementType(spaceGroupDetail.feature.pavement)}</RightItem>
          </FormRow>
          <FormRow>
            <LeftItem>時間</LeftItem>
            <RightItem>{calculateAvailableTime(spaceGroupDetail.feature.time)}</RightItem>
          </FormRow>
          <FormRow>
            <LeftItem>付与物</LeftItem>
            <RightItem>{spaceGroupDetail.feature.loan ? spaceGroupDetail.feature.loan : "なし"}</RightItem>
          </FormRow>
        </Wrap>
        <Wrap>
          <Label>契約情報</Label>
          <FormRow>
            <LeftItem>契約期間</LeftItem>
            <RightItem>{`${spaceGroupDetail.contract.years}年`}</RightItem>
          </FormRow>
          <FormRow>
            <LeftItem>更新量</LeftItem>
            <RightItem>{`${convertNumberToLocaleString(spaceGroupDetail.contract.renewalFee)}（税込）`}</RightItem>
          </FormRow>
          <FormRow>
            <LeftItem>日割</LeftItem>
            <RightItem>{convertDailyRateType(spaceGroupDetail.contract.dailyRateType)}</RightItem>
          </FormRow>
        </Wrap>
        <Wrap>
          <Label>備考</Label>
          <FormRow>
            <RightItem maxWidth="100%">{spaceGroupDetail.remarks}</RightItem>
          </FormRow>
        </Wrap>
      </HalfSizeContent>
    </ContentArea>
  );
};

export default SpaceGroupInfo;
