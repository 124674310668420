import { useNavigate } from "react-router-dom";

import Breadcrumb from "components/Breadcrumb";
import { Board, ContentArea, PageTop, Title, TitleArea } from "components/LayoutParts";

/**
 * TODO: 貸主を作成する画面
 */
const CreateOwnerEntity = () => {
  const navigate = useNavigate();

  return (
    <Board>
      <PageTop />
      <TitleArea>
        <Breadcrumb
          currentPageName="貸主を追加する"
          breadcrumbItems={[{ pageName: "貸主", onClick: () => navigate("/pit_port/owner_entities") }]}
        />
      </TitleArea>
      <ContentArea>
        <div>
          <Title>貸主登録画面</Title>
        </div>
      </ContentArea>
    </Board>
  );
};

export default CreateOwnerEntity;
