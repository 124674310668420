import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import { Board as BaseBoard, TitleArea, FormRow, FooterArea, ContentArea, AlertArea } from "components/LayoutParts";
import RadioButton from "components/RadioButton";
import Select from "components/Select";
import TextButton from "components/TextButton";
import TextForm from "components/TextForm";
import UploadForm from "components/UploadForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import { HOURS, MINUTES } from "constants/time";
import { useCreateProperty, initSpace } from "pages/PitPort/Property/api";
import SpaceForm from "pages/PitPort/Property/SpaceForm";
import { timeFormat, timeToDayjs } from "utils/dateTimeUtils";

// パーツ定義
const Board = styled(BaseBoard)`
  margin-bottom: 88px;
`;
const Label = styled.div`
  min-width: 160px;
  margin: 0 5px;
`;
const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
`;
const ErrorArea = styled.div<{
  isErrorDisplay: boolean;
}>`
  display: ${(props) => (props.isErrorDisplay ? "block" : "none")};
  position: absolute;
  white-space: nowrap;
  color: ${color.attention};
  margin: 2px 0;
`;
const ErrorMessage = styled.span`
  font-size: ${font.size12};
`;

const CreateProperty = () => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [latlng, setLatlng] = useState("");
  const [availableStartHour, setAvailableStartHour] = useState("9");
  const [availableStartMinute, setAvailableStartMinute] = useState("00");
  const [availableEndHour, setAvailableEndHour] = useState("19");
  const [availableEndMinute, setAvailableEndMinute] = useState("00");
  const [usageFeePerFifteenMinutes, setUsageFeePerFifteenMinutes] = useState(0);
  const [status, setStatus] = useState(1); // 1:true、0:false
  const [images, setImages] = useState<File[]>([]);
  const [spaces, setSpaces] = useState<Space[]>([initSpace]);
  // TODO: 貸主エンティティーを登録できるようにする
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ownerEntityId, setOwnerEntityId] = useState(1); // landit

  const [isModalShow, setIsModalShow] = useState(false);
  const [ModalConfig, setModalConfig] = useState<UserModalConfig>({
    onClickOutside: () => {
      return;
    },
    subtitle: "完了しました",
    main: {
      buttonType: "secondary",
      onClick: () => {
        return;
      },
      label: "閉じる",
    },
  });

  const showModal = (config: UserModalConfig) => {
    setModalConfig(config);
    setIsModalShow(true);
  };
  const hideModal = () => {
    setIsModalShow(false);
  };

  const navigate = useNavigate();
  const { create } = useCreateProperty();

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const changeAddress = (e: React.ChangeEvent<HTMLInputElement>) => setAddress(e.target.value);
  const changeLatlng = (e: React.ChangeEvent<HTMLInputElement>) => setLatlng(e.target.value);
  const changeUsageFeePerFifteenMinutes = (e: React.ChangeEvent<HTMLInputElement>) =>
    setUsageFeePerFifteenMinutes(Number(e.target.value));

  const isErrorName = !(name.length > 0);
  const isErrorAddress = !(address.length > 0);
  const isErrorLatlng = !/^[0-9.]+, [0-9.]+$/.test(latlng);
  const availableStartTimeToDayjs = timeToDayjs(availableStartHour, availableStartMinute);
  const availableEndTimeToDayjs = timeToDayjs(availableEndHour, availableEndMinute);
  const isErrorAfterStartTime = availableStartTimeToDayjs.isAfter(availableEndTimeToDayjs);
  const isErrorUsageFee = !/^([1-9][0-9]*)$/.test(`${usageFeePerFifteenMinutes}`);
  const isErrorSpaceName = spaces.filter((space) => space.name.length === 0).length > 0;
  const isErrorSpaceWidth = spaces.filter((space) => space.width === 0).length > 0;
  const isErrorSpaceLength = spaces.filter((space) => space.length === 0).length > 0;
  const filteredSpacesByStandId = spaces.filter((space) => space.standId);
  const filteredSpacesByContId = spaces.filter((space) => space.contId);
  const isErrorContIdLength = spaces.filter((space) => space.contId && space.contId?.length !== 12).length > 0;
  const isErrorDuplicateStandIds =
    new Set(filteredSpacesByStandId.map((space) => space.standId)).size !== filteredSpacesByStandId.length;
  const isErrorDuplicateContIds =
    new Set(filteredSpacesByContId.map((space) => space.contId)).size !== filteredSpacesByContId.length;
  const canSubmit =
    !isErrorName &&
    !isErrorAddress &&
    !isErrorLatlng &&
    !isErrorAfterStartTime &&
    !isErrorUsageFee &&
    !isErrorSpaceName &&
    !isErrorDuplicateStandIds &&
    !isErrorDuplicateContIds &&
    !isErrorSpaceLength &&
    !isErrorSpaceWidth &&
    !isErrorContIdLength;

  const save = useCallback(async () => {
    const result = await create(images, {
      name,
      address,
      lat: latlng.split(", ")[0],
      lng: latlng.split(", ")[1],
      usageFeePerFifteenMinutes,
      availableStartDatetime: timeFormat(availableStartHour, availableStartMinute),
      availableEndDatetime: timeFormat(availableEndHour, availableEndMinute),
      status: status === 0 ? 0 : 1,
      spaces,
      ownerEntityId: ownerEntityId ?? 1, // landit
    });
    result === 201
      ? showModal({
          onClickOutside: hideModal,
          subtitle: "追加しました",
          main: {
            buttonType: "secondary",
            onClick: () => navigate("/pit_port/property"),
            label: "閉じる",
          },
        })
      : showModal({
          onClickOutside: hideModal,
          subtitle: "送信に失敗しました",
          main: {
            buttonType: "secondary",
            onClick: hideModal,
            label: "閉じる",
          },
          errorMessage: "時間をおいてもう一度お試しください",
        });
  }, [
    address,
    availableEndHour,
    availableEndMinute,
    availableStartHour,
    availableStartMinute,
    create,
    images,
    latlng,
    name,
    usageFeePerFifteenMinutes,
    status,
    spaces,
    navigate,
    ownerEntityId,
  ]);

  return (
    <Board>
      <TitleArea>
        <Breadcrumb
          currentPageName="駐車場を登録"
          breadcrumbItems={[{ pageName: "駐車場", onClick: () => navigate("/pit_port/property") }]}
        />
      </TitleArea>
      <ContentArea>
        <FormRow>
          <Label>駐車場名</Label>
          <TextForm
            type="text"
            width="350px"
            value={name}
            placeholder="例）ザイマックス三田ビル"
            required
            onChange={changeName}
            isError={isErrorName}
            errorMessage="物件名を入力してください"
          />
        </FormRow>
        <FormRow>
          <Label>住所</Label>
          <TextForm
            type="text"
            width="540px"
            value={address}
            placeholder="例）東京都港区芝5-13-11"
            required
            onChange={changeAddress}
            isError={isErrorAddress}
            errorMessage="住所を入力してください"
          />
          <div style={{ marginLeft: "16px" }}>
            <TextButton
              label="Googleマップで確認"
              onClick={() => {
                window.open(`http://maps.google.co.jp/maps?q=${address}`, "_blank");
                return;
              }}
            />
          </div>
        </FormRow>
        <FormRow>
          <Label>緯度経度</Label>
          <TextForm
            type="text"
            width="540px"
            value={latlng}
            placeholder="例）35.649120216601474, 139.745112924581"
            required
            onChange={changeLatlng}
            isError={isErrorLatlng}
            errorMessage="緯度, 経度 の形式で入力してください"
          />
        </FormRow>
        <FormRow style={{ position: "relative" }}>
          <Label>予約可能時間</Label>
          <div style={{ marginRight: "8px" }}>開始</div>
          <Select
            choices={HOURS}
            value={availableStartHour}
            width="100px"
            required
            onSelect={(selected) => {
              setAvailableStartHour(selected);
              selected == "24" ? setAvailableStartMinute("00") : null;
            }}
          />
          <div style={{ marginLeft: "8px", marginRight: "8px" }}>時</div>
          <Select
            choices={MINUTES}
            value={availableStartMinute}
            width="100px"
            required
            onSelect={(selected) => {
              setAvailableStartMinute(availableStartHour != "24" ? selected : "00");
            }}
          />
          <div style={{ marginLeft: "8px" }}>分</div>
          <div style={{ marginLeft: "32px", marginRight: "8px" }}>終了</div>
          <Select
            choices={HOURS}
            value={availableEndHour}
            width="100px"
            required
            onSelect={(selected) => {
              setAvailableEndHour(selected);
              selected == "24" ? setAvailableEndMinute("00") : null;
            }}
          />
          <div style={{ marginLeft: "8px", marginRight: "8px" }}>時</div>
          <Select
            choices={MINUTES}
            value={availableEndMinute}
            width="100px"
            required
            onSelect={(selected) => {
              setAvailableEndMinute(availableEndHour != "24" ? selected : "00");
            }}
          />
          <div style={{ marginLeft: "8px" }}>分</div>
          <ErrorArea
            isErrorDisplay={isErrorAfterStartTime}
            style={{ position: "absolute", bottom: "0", left: "31rem" }}
          >
            <ErrorMessage>開始時間より後の時間を選択してください</ErrorMessage>
          </ErrorArea>
        </FormRow>
        <FormRow>
          <Label>料金（15分）</Label>
          <TextForm
            type="number"
            width="100px"
            value={usageFeePerFifteenMinutes}
            placeholder="例）50"
            required
            onChange={changeUsageFeePerFifteenMinutes}
            isError={isErrorUsageFee}
            errorMessage="1円以上を入力してください"
          />
          <div style={{ marginLeft: "8px" }}>円</div>
        </FormRow>
        <FormRow>
          <Label>画像</Label>
          <div style={{ width: "520px" }}>
            <UploadForm unUploadedImages={images} setUnUploadedImages={setImages} />
          </div>
        </FormRow>
        <FormRow>
          <Label>状態</Label>
          <RadioButton
            id="状態"
            value={status}
            choices={[
              {
                label: "利用可",
                value: 1,
              },
              {
                label: "利用不可",
                value: 0,
              },
            ]}
            onChange={(e) => setStatus(Number(e.target.value))}
          />
        </FormRow>
      </ContentArea>
      {spaces.length === 0 && (
        <AlertArea>
          <FontAwesomeIcon icon={faCircleExclamation} />
          <span style={{ color: color.text.black, marginLeft: "8px" }}>区画を追加してください</span>
        </AlertArea>
      )}
      {spaces.map((space, index) => (
        <SpaceForm key={`space_${index}`} index={index} space={space} setSpaces={setSpaces} spaces={spaces} />
      ))}
      <TitleArea>
        <Button label="区画を追加する" onClick={() => setSpaces((prev) => [...prev, initSpace])} type="secondary" />
      </TitleArea>
      <FooterArea>
        <CancelWrap>
          <Button type="secondary" onClick={() => navigate("/pit_port/property")} label="キャンセル" width="160px" />
        </CancelWrap>
        <SaveWrap>
          <Annotation>追加するまで変更内容は反映されません</Annotation>
          <Button type="primary" onClick={save} label="この駐車場を追加" disabled={!canSubmit} width="160px" />
        </SaveWrap>
      </FooterArea>
      <UserModal isVisible={isModalShow} config={ModalConfig}></UserModal>
    </Board>
  );
};

export default CreateProperty;
