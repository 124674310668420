import React from "react";

type PageState<T, U> = {
  [key: string]: {
    currentPage: number;
    sort: SortOption<T>;
    filter: U;
    search: string;
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type State<T = any | undefined, U = any | undefined> = {
  state: PageState<T, U>;
  setState: React.Dispatch<React.SetStateAction<PageState<T, U>>>;
};

const PageStateContext = React.createContext({} as State);

export default PageStateContext;
