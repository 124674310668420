import Company from "pages/PitPort/Company";
import EditCompany from "pages/PitPort/Company/EditCompany";
import TitleTabArea from "pages/PitPort/component/TitleTabArea";
import ControlBox from "pages/PitPort/ControlBox";
import CreateControlBox from "pages/PitPort/ControlBox/CreateControlBox";
import EditControlBox from "pages/PitPort/ControlBox/EditControlBox";
import Customer from "pages/PitPort/Customer";
import EditCustomer from "pages/PitPort/Customer/EditCustomer";
import OwnerEntity from "pages/PitPort/OwnerEntity";
import CreateOwnerEntity from "pages/PitPort/OwnerEntity/CreateOwnerEntity";
import OwnerEntityDetail from "pages/PitPort/OwnerEntity/OwnerEntityDetail";
import CreateOwnerUser from "pages/PitPort/OwnerEntity/OwnerUser/create";
import OwnerUsers from "pages/PitPort/OwnerEntity/OwnerUsers";
import Property from "pages/PitPort/Property";
import CreateProperty from "pages/PitPort/Property/CreateProperty";
import EditProperty from "pages/PitPort/Property/EditProperty";
import SystemNotification from "pages/PitPort/SystemNotification";
import CreateSystemNotification from "pages/PitPort/SystemNotification/CreateSystemNotification";
import SystemNotificationDetail from "pages/PitPort/SystemNotification/SystemNotificationDetail";
import Usage from "pages/PitPort/Usage";
import User from "pages/PitPort/User";
import CreateUser from "pages/PitPort/User/CreateUser";
import EditUser from "pages/PitPort/User/EditUser";

const PitPort = {
  User,
  Customer,
  CreateUser,
  EditUser,
  EditCustomer,
  Company,
  EditCompany,
  Property,
  EditProperty,
  CreateProperty,
  CreateControlBox,
  EditControlBox,
  Usage,
  ControlBox,
  OwnerEntity,
  OwnerEntityDetail,
  OwnerUsers,
  CreateOwnerUser,
  CreateOwnerEntity,
  TitleTabArea,
  SystemNotification,
  SystemNotificationDetail,
  CreateSystemNotification,
};

export default PitPort;
