import React from "react";
import { useSearchParams } from "react-router-dom";

import PageStateContext from "contexts/PageStateContext";

const parseFilter = (filter: string | number | undefined | null) => {
  if (filter == null) return undefined;
  if (typeof Number(filter) === "number") {
    return Number(filter);
  }
  return filter.toString();
};

/**
 * T : ソートキーのデータ型
 * U : フィルターキーのデータ型
 */
const usePageState = <T, U>({
  pageSymbol,
  defaultSort,
  defaultFilter,
}: {
  pageSymbol: string;
  defaultSort?: SortOption<T>;
  defaultFilter?: U;
}) => {
  const { state, setState } = React.useContext(PageStateContext);

  const [searchParams, setSearchParams] = useSearchParams();

  // ページの状態が存在しない場合、初期化(クエリパラメータがあればクエリパラメータで初期化)

  const search = searchParams.get("search") || "";
  const currentPage = Number(searchParams.get("page")) || 1;
  const sortByColumn = searchParams.get("sortByColumn") || defaultSort?.sortByColumn;
  const direction = searchParams.get("direction") || defaultSort?.direction;
  const filter = parseFilter(searchParams.get("filter")) || defaultFilter;

  React.useEffect(() => {
    if (!state[pageSymbol]) {
      setState((prevState) => ({
        ...prevState,
        [pageSymbol]: {
          currentPage,
          filter,
          sort: { sortByColumn, direction } as SortOption<T>,
          search,
        },
      }));
    }
  }, [pageSymbol, setState, state, defaultFilter, defaultSort, search, currentPage, sortByColumn, direction, filter]);

  const view = state[pageSymbol] || {
    currentPage: "",
    filter: "" as U,
    order: defaultSort || { sortByColumn: undefined, direction: undefined as SortDirection },
    search: "",
  };

  const setCurrentPage: React.Dispatch<React.SetStateAction<number>> = (value) => {
    setSearchParams(
      (prev) => {
        const newParams = new URLSearchParams(prev);
        const nextValue = typeof value === "function" ? value(view.currentPage) : value;
        newParams.set("page", nextValue.toString());
        return newParams;
      },
      { replace: true }
    );
    setState((prevState) => ({
      ...prevState,
      [pageSymbol]: {
        ...prevState[pageSymbol],
        currentPage: typeof value === "function" ? value(view.currentPage) : value,
      },
    }));
  };

  const setFilter: React.Dispatch<React.SetStateAction<U>> = (value) => {
    setSearchParams(
      (prev) => {
        const newParams = new URLSearchParams(prev);
        const nextValue = typeof value === "function" ? (value as (prevState: U) => U)(view.filter) : value;
        newParams.set("filter", (nextValue as string).toString());
        newParams.set("page", "1");
        return newParams;
      },
      {
        replace: true,
      }
    );
    setState((prevState) => ({
      ...prevState,
      [pageSymbol]: {
        ...prevState[pageSymbol],
        filter: typeof value === "function" ? (value as (prevState: U) => U)(view.filter) : value,
        currentPage: 1,
      },
    }));
  };

  const setSort: React.Dispatch<React.SetStateAction<SortOption<T>>> = (value) => {
    setSearchParams(
      (prev) => {
        const newParams = new URLSearchParams(prev);

        let nextSortByColumn = view.sort.sortByColumn;
        let nextDirection = view.sort.direction;

        if (typeof value === "function") {
          const updated = value(view.sort);
          nextSortByColumn = updated.sortByColumn;
          nextDirection = updated.direction;
        } else {
          nextSortByColumn = value.sortByColumn;
          nextDirection = value.direction;
        }

        if (nextSortByColumn) newParams.set("sortByColumn", nextSortByColumn as string);
        else newParams.delete("sortByColumn");

        if (nextDirection) newParams.set("direction", nextDirection);
        else newParams.delete("direction");

        newParams.set("page", "1");

        return newParams;
      },
      {
        replace: true,
      }
    );
    setState((prevState) => {
      const previousSort = prevState[pageSymbol]?.sort || { sortByColumn: null, direction: null };

      const newSort = typeof value === "function" ? value(previousSort) : value;

      return {
        ...prevState,
        [pageSymbol]: {
          ...prevState[pageSymbol],
          sort: { ...newSort },
          currentPage: 1,
        },
      };
    });
  };

  const setSearch: React.Dispatch<React.SetStateAction<string>> = (value) => {
    setSearchParams(
      (prev) => {
        const newParams = new URLSearchParams(prev);
        const nextValue = typeof value === "function" ? value(view.search) : value;

        if (nextValue) newParams.set("search", nextValue);
        else newParams.delete("search");

        newParams.set("page", "1");
        return newParams;
      },
      {
        replace: true,
      }
    );
    setState((prevState) => ({
      ...prevState,
      [pageSymbol]: {
        ...prevState[pageSymbol],
        search: typeof value === "function" ? value(view.search) : value,
        currentPage: 1,
      },
    }));
  };

  return {
    currentPage: view.currentPage,
    setCurrentPage,
    filter: view.filter,
    setFilter,
    sort: view.sort ?? defaultSort,
    setSort,
    search: view.search,
    setSearch,
  };
};

export default usePageState;
