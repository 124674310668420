import { ContentArea, Title } from "components/LayoutParts";

/**
 * 貸主詳細画面(仮)
 */
const OwnerEntityDetail = () => {
  return (
    <ContentArea>
      <div>
        <Title>貸主詳細画面</Title>
      </div>
    </ContentArea>
  );
};

export default OwnerEntityDetail;
