import { useCallback, useEffect, useState } from "react";

import { Board, TitleArea, Title, PageTop } from "components/LayoutParts";
import Loading from "components/Loading";
import UserModal, { UserModalConfig } from "components/UserModal";
import { sortDirection } from "constants/direction";
import { useFindCustomers } from "pages/PitPort/Customer/api";
import DataTable from "pages/PitPort/Customer/DataTable";
import ActionArea from "pages/PitPort/Customer/DataTable/ActionArea";
import { formattedCustomerData } from "pages/PitPort/utils/formatters";
import { jumpUpToElement } from "utils/animationUtils";

// 本体
const Customer = () => {
  const DISPLAY_FILTER_KEY = "display-customer-filter-status";
  const displayFilterStatus = (): PitPortUserDisplayFilterStatus => {
    const storageStatus = localStorage.getItem(DISPLAY_FILTER_KEY);
    if (!storageStatus) return "enabled";
    return JSON.parse(storageStatus);
  };

  const [originList, setOriginList] = useState<PitPortCustomer[]>([]);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isModalShow, setIsModalShow] = useState(false);
  const [sortOption, setSortOption] = useState<SortOption<SortByPitPortCustomer>>({
    sortByColumn: "id",
    direction: sortDirection.desc,
  });
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedFilter, setSelectedFilter] = useState<PitPortUserDisplayFilterStatus>(displayFilterStatus());

  const hideModal = () => setIsModalShow(false);
  const showModal = () => setIsModalShow(true);

  const userModalConfig: UserModalConfig = {
    onClickOutside: hideModal,
    subtitle: "送信に失敗しました",
    main: {
      buttonType: "secondary",
      onClick: hideModal,
      label: "閉じる",
    },
    errorMessage: "時間をおいてもう一度お試しください",
  };

  const { data, error } = useFindCustomers({
    pageIndex: currentPageIndex,
    status: selectedFilter,
    orderBy: sortOption.sortByColumn,
    orderType: sortOption.direction,
    keyword: searchKeyword,
  });

  const fetch = useCallback(async () => {
    jumpUpToElement("page-top");
    if (error) {
      showModal();
      return;
    }
    if (!data) return;
    setTotalPages(data.totalPages);
    setOriginList(formattedCustomerData(data).data);
  }, [data, error]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);
  };

  const onFilterButtonClick = (statusforDisplay: PitPortUserDisplayFilterStatus) => {
    setSelectedFilter(statusforDisplay);
    localStorage.setItem(DISPLAY_FILTER_KEY, JSON.stringify(selectedFilter));
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      <Board>
        <PageTop />
        <TitleArea>
          <Title>一般ユーザ</Title>
        </TitleArea>
        <ActionArea
          searchKeyword={searchKeyword}
          selectedFilter={selectedFilter}
          onSearchChange={onSearchChange}
          onFilterButtonClick={onFilterButtonClick}
        />
        {data ? (
          <DataTable
            userList={originList}
            totalPages={totalPages}
            pageIndex={currentPageIndex}
            sortOption={sortOption}
            setPageIndex={setCurrentPageIndex}
            setSortOption={setSortOption}
            searchKeyword={searchKeyword}
            selectedFilter={selectedFilter}
          />
        ) : (
          <Loading />
        )}
      </Board>
      <UserModal isVisible={isModalShow} config={userModalConfig}></UserModal>
    </>
  );
};

export default Customer;
