import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { Board, PageTop, Title, TitleArea } from "components/LayoutParts";
import Loading from "components/Loading";
import usePageState from "hooks/usePageState";
import { useFindAllEdgeDevice } from "pages/AimoParking/EdgeDevice/api";
import DataTable from "pages/AimoParking/EdgeDevice/DataTable";
import { jumpUpToElement } from "utils/animationUtils";

const ButtonWrap = styled.div`
  margin-left: auto;
`;

const Margin = styled.div`
  margin-top: 16px;
`;

const EdgeDevice = () => {
  const [totalPages, setTotalPages] = useState<number>(1);

  const { currentPage, setCurrentPage } = usePageState({
    pageSymbol: "aimoParkingEdgeDeviceListView",
  });
  const navigate = useNavigate();
  const { data: edgeDevices, pageInfo } = useFindAllEdgeDevice({
    pageIndex: currentPage,
  });

  const onClickEdgeDeviceAdd = () => {
    navigate("/aimo_parking/edge_device/create");
  };

  useEffect(() => {
    setTotalPages(pageInfo?.totalPages ?? 1);
    return () => {
      jumpUpToElement("root");
    };
  }, [pageInfo?.totalPages]);

  return (
    <Board>
      <PageTop />
      <TitleArea>
        <Title>エッジデバイス</Title>
        <ButtonWrap>
          <Button label="エッジデバイスを登録" onClick={onClickEdgeDeviceAdd} type="primary" width="191px" />
        </ButtonWrap>
      </TitleArea>
      <Margin />
      {edgeDevices ? (
        <DataTable
          edgeDeviceList={edgeDevices}
          totalPages={totalPages}
          pageIndex={currentPage}
          setPageIndex={setCurrentPage}
        />
      ) : (
        <Loading />
      )}
    </Board>
  );
};

export default EdgeDevice;
