import { useCallback } from "react";

import { usePitPortClient } from "hooks/api/usePitPortClient";
// import { formatQueryParams } from "utils/stringUtils";

// TODO: ページネーションと検索を実装する
// /**
//  * 貸主ユーザーを取得する
//  * @param ownerEntityId
//  * @param keyword
//  * @param order
//  * @param orderBy
//  * @param pageIndex
//  * @param pageSize
//  * @returns OwnerUsersResponse
//  */
// export const useFindAllOwnerUsers = ({
//   ownerEntityId,
//   keyword,
//   order,
//   orderBy,
//   pageIndex,
//   pageSize = 10,
// }: {
//   ownerEntityId: string;
//   pageIndex?: number;
//   orderBy?: string;
//   order?: string;
//   keyword?: string;
//   pageSize?: number;
// }) => {
//   const { data, error } = useSwr<OwnerUsersResponse>(
//     `/owner_entities/${ownerEntityId}/owner_users?${formatQueryParams({
//       keyword,
//       pageSize,
//       pageIndex,
//       orderBy,
//       order,
//     })}`
//   );

//   return {
//     data: data?.data,
//     error,
//     pageInfo: data?.pagination && {
//       hasMore: data.pagination.hasMore,
//       pageIndex: data.pagination.pageIndex,
//       totalPages: data.pagination.totalPages,
//     },
//   };
// };

/**
 * 貸主ユーザーを作成する
 * @param ownerEntityId
 * @param data
 * @returns OwnerUser
 */
export const useCreateOwnerUser = () => {
  const client = usePitPortClient();

  const create = useCallback(
    async (ownerEntityId: string, data: OwnerUserCreateRequest) => {
      return await client.client({
        method: "POST",
        url: `/owner_entities/${ownerEntityId}/owner_users`,
        data: {
          ...data,
        },
      });
    },
    [client]
  );

  return { create };
};
