import { useSwr } from "hooks/useSwr";

/**
 * 貸主を取得する
 * @param ownerEntityId
 * @returns OwnerUser[]
 */
export const useFindOwnerUsers = ({ ownerEntityId }: { ownerEntityId: string }) => {
  const { data, error } = useSwr<OwnerUsersResponse>(`/owner_entities/${ownerEntityId}/owner_users`);

  return {
    data: data?.data,
    error,
  };
};
